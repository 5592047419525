@import 'Trifold';

.has-reviewers {
  border: 2px solid rgba(#5386b0, .2);
}

.user-is-reviewer {
  border: 2px solid rgba(blue, .5);
}

.invite-review-type {
  max-width: 500px;
  margin-bottom: 2%;
  border: 2px solid rgba(#5386b0, .2);
}

.postpanel-preview {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.postpanel-nav {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 10%;
  min-height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F5FBFF;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  box-shadow: 0 0px 10px mix(rgba(#C9E6FE, .2), rgba(154,160,185, 0.2)), 0 15px 40px mix(rgba(#C9E6FE, .2), rgba(166,173,201, .05)), 1.5px 1.5px 4px 0 mix(rgba(#C9E6FE, .2), rgba(166, 173, 201, .5));
}

.postpanel-return {
  display: flex;
  width: 20%;
  height: 50%;
  padding-left: 2%;
  min-width: 145px;
}

.nav-button {
  border-radius: 3px;
  border-bottom: 0;
}

.postpanel-delete {
  display: flex;
  height: 50%;
  min-width: 145px;
  padding-right: 2%;
}

.postpanel-nav-spacer {
  flex-grow: 1;
}

.postpanel-add-reviewer {
  @extend .basic-fold;
  width: 100%;
  max-width: 500px; border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  max-height: 10%;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: transparent;
  box-shadow: none;
}

.postpanel-added-reviewers {
  padding: 2%;
  padding-left: 0;
  padding-right: 0;
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
}

.postpanel-added-reviewer {
  width: 100%;
}

.postpanel-add-reviewer-control {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postpanel-add-reviewer-input {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-right: 2%;
  max-height: 32px;
  min-height: 48px;
}

// .postpanel-add-reviewer-input fieldset {
//   border-radius: 0;
// }

.postpanel-add-reviewer-button {
  display: flex;
  height: 68%;
  min-width: 150px;
  justify-content: center;
  align-items: center;
}

.submit-added-reviewers-container {
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.submit-added-reviewers-button {


}

.postpanel {
  width: 100%;
  background-color: #F5FBFF;
  display: flex;
  flex-direction: row; 

  &-reviewer-button {
    max-width: 500px;
  }

  &-reviewer-selection {
    padding-top: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 1%;
  }

  &-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 320px;
    flex: 1;
    overflow-y: hidden;
  }

  &-padded {
    padding: 2%;
    border-radius-bottom-left: 5px;
    border-radius-bottom-right: 5px;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: scroll;
  }

  &-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }


  &-far-right-col {
    display: flex;
    flex-direction: column;
  }

  &-top-line {
    display: flex;
    align-items: center;
  }

  &-item {
    margin-top: .5%;
    margin-bottom: .5%;
  }

  &-reviewstatus {
    max-width: 500px;
    border-radius: 2px;
    padding: 1%;
    display: flex;
    flex-direction: column;
    margin-top: 1%;
    margin-bottom: 1%;
    background-color: #fcfcfc;
  }

  &-reviewer {
    display: flex;
    align-items: center;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 1%;
    padding-right: 1.5%;
    border-radius: 2px;
  }
}

.reviewer-avatar {
  border-radius: 50%;
  max-height: 42px;
}

.reviewer-status {
  max-width: 30px;
  max-height: 30px;
}

.reviewstatus-icon-tooltip {
  position: relative;
  width: 100%;
  height: 100%;
}

.loader-button-wrap {
  padding-top: 2.5%;
}

.review-type-display {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1%;
  padding-right: 2%;
  padding-left: 2%;

  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &-title {
      margin-right: 2%;
    }

    &-spacer {
      flex-grow: 1;
    }
  }
}

.request-review-wrapper {
  padding: 1%;
  padding-bottom: 0;
}

.request-review-submit {
  padding: 4%;
  padding-left: 0;
  padding-right: 0;
  max-height: 100px;
}

.request-reviewer-collapse {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #F5F5F5;
  border-radius: 3px;
}

@media (max-width: 850px) {
  .postpanel {
    &-wrapper {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    &-nav {
      border-radius: 0px;
      min-height: 60px;
    }
  }

}

@media (max-width: 650px) {
  .postpanel-return {
    width: 45%;
    height: 60%;
  }

  .nav-button {
    padding: 3%;
  }
  
  .postpanel-delete {
    width: 45%;
    height: 60%;
  }

  .postpanel-add-reviewer {
    max-height: 40%;
  }

  .postpanel-add-reviewer-input {
    padding-right: 0;
    min-height: 48px;
  }

  .postpanel-add-reviewer-control {
    flex-wrap: wrap;
  }

  .postpanel-add-reviewer-button {
    width: 100%;
    min-width: 125px;
    padding: 2%;
    padding-right: 0;
    padding-left: 0;

    height: 30%;
    min-height: 50px;
  }
}

@media (max-width: 450px) {
  .nav-button {
    padding: 1%;
  }
}  


@media (max-height: 600px) {
  .postpanel-nav {
    min-height: 60px;
  }
}
