:root {
  --intense-black: #4A4A4A; 
  --title-black: #686868;
  --strong-black: #797979;
  --strong-blackt: rgba(121, 121, 121, 0.5);
  --light-black: rgba(0, 0, 0, .5);
}

.text {
  font-family: Helvetica Neue;
  font-style: normal;

  &__intense {
    font-weight: bold;
    font-size: 4.5vw;
    line-height: 4.5vw;
    color: var(--intense-black);
  }

  &__title {
    font-weight: bold;
    font-size: 4vw;
    line-height: 4vw;
    color: var(--title-black);
  }

  &__strong {
    font-weight: bold;
    font-size: 2.4vw;
    line-height: 3vw;
    color: var(--strong-black);
  }

  &__detail {
    font-size: 22px;
    line-height: 28px;
    color: var(--strong-black);
  }

  &__dynamic {
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    color: var(--strong-black);
  }

  &__config {
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: var(--strong-black);
  }


  &__disabled {
    font-size: 22px;
    line-height: 28px;
    color: var(--strong-blackt);
  }

  &__info {
    font-size: 14px; 
    line-height: 16px;
    color: var(--light-black);
  }

  &__header {
    font-size: 14px;
    line-height: 16px;
    color: var(--title-black);
  }
}

@media (min-width: 1200px) {
  .text {
    &__intense {
      font-size: 55px;
      line-height: 60px;

    }
    &__dynamic {
      font-size: 30px;
      line-height: 32px;
    }
  }
}

@media (min-width: 1400px) {
  .text {
    &__title {
      font-size: 50px;
      line-height: 45px;
    }
    &__intense {
      font-size: 55px;
      line-height: 60px;

    }
    &__strong {
      font-size: 33px;
      line-height: 35px;
    }
  }
}

@media (max-width: 800px) {
  .text {
    &__title {
      font-size: 25px;
    }
  }
}

@media (max-width: 700px) {
  .text {
    &__intense {
      font-size: 32px;
      line-height: 34px;
    }
  }
}

@media (max-width: 600px) {
  .text {
    &__intense {
      font-size: 30px;
      line-height: 32px;
    }

    &__header {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

@media (max-width: 540px) {
  .text {
    &__strong {
      font-size: 17px;
      line-height: 19px;
    }

    &__intense {
      font-size: 28px;
      line-height: 30px;
    }
  }
}

@media (max-width: 400px) {
  .text {
    &__intense {
      font-size: 25px;
      line-height: 27px;
    }

    &__strong {
      font-size: 15px;
      line-height: 20px;
    }

    &__title {
      font-size: 8;
      line-height: 8vw;
    }
  }
}
