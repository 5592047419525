.doc-link {
  display: flex;
  flex-direction: row;
}

.docs-image {
  width: 20%;
  height: 20%;
  max-width: 80px;
  flex: 0;
}

.added-reviewers {
  // border: 2px solid rgba(0, 0, 0, 0.1);
  // border-radius: 5px;
  // padding: 2%;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  padding-top: 3%;
}
