.form-check-group {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding-top: 3%;
  padding-bottom: 3%;
}

.split-check-group {
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  justify-content: space-evenly;
}
