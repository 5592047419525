.privacy-wrapper {
  padding: 1%;
  display: flex;
  flex-direction: column;
}

.privacy-header {
  display: flex;
}

.privacy-header-title {
  display: flex;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.logo {
  flex-basis: content;
  height: min-content;
  &__image {
    position: relative;
    width: 30%;
    margin-top: 3%;
    margin-left: 3%;
    min-width: 170px;
    max-width: 300px;
  }
}
