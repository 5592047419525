.grid {
  width: 100%;
  height: 100%;
  display: grid;
  background-color: #fcfcfc;
  overflow: hidden;
  font-family: "Helvetica Neue", Helvetica;
  font-size: 18px;

  &-minus-pag {
    grid-template-rows: 64px auto 5px;
  }

  &-with-pag {
    grid-template-rows: 64px auto 48px;
  }

  &-header {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 24px minmax(max-content, max-content) auto 24px;
    background-color: #fcfcfc;
    box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.15);

    &-title {
      grid-column-start: 2;
      display: flex;
      align-items:center;
      margin-top: auto;
      margin-bottom: auto;
      padding-right: 20px;
      font-size: 16px;
      justify-content: center;
      align-items: center;

      &-text {
        padding-right: 3px;
      }

      &-icon {
        color: rgba(0, 0, 0, 0.55);
      }
    }

    &-search {
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      grid-column-start: 3;
      align-items: center;
      margin-top: auto;
      margin-bottom: auto;
    }

    &-shadow {
      position: absolute;
      top: -5%;
      z-index: 10;
      width: 100%;
      height: 90%;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.0);
    }
  }

  &-content {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
  }

  &-content::-webkit-scrollbar {
    display: none;
  }

  &-pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-shadow: 0px -1px 9px rgba(0, 0, 0, 0.2);
  }
}

.rowgrid {
  width: 100%;
  display: grid;
  grid-template-columns: 24px minmax(max-content, max-content) auto 64px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);


  &-title {
    grid-column-start: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-content {
    grid-column-start: 3;
  }
  
  &-expand-control {
    grid-column-start: 4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
