.landing {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  min-height: 100%;
  width: 100%; 
  display: grid;
  grid-template-rows: auto;
  background-color: #E7F8FF;


  &__spacer {
    height: 25px;
    width: 100%;
  }

  &-bottom-nav {
    width: 100%;
    min-height: 64px;
    background-color: #5386b0;
    display: flex;
    justify-content: center;
    align-items: center;

    > a {
      color: white;
      text-decoration: none;
    }
  }

  &-button {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 3%;
    width: 90%;
    max-width: 450px;
    min-width: 250px;
    margin-top: 3%;
  }


  &__logo {
    flex-basis: content;
    height: min-content;
    &__image {
      position: relative;
      width: 30%;
      margin-top: 3%;
      margin-left: 3%;
      min-width: 170px;
      max-width: 300px;
    }
  }


  &__fold {
    background-color: #F5FBFF;
    border-radius: 7px;
    // box-shadow: 1.5px 1.5px 4px 0 hsla(0%, 0%, 0%, .5);
    box-shadow: 0 5px 10px mix(rgba(#C9E6FE, .2), rgba(154,160,185, 0.2)), 0 15px 40px mix(rgba(#C9E6FE, .2), rgba(166,173,201, .05)), 1.5px 1.5px 4px 0 mix(rgba(#C9E6FE, .2), rgba(166, 173, 201, .5));
    width: 80%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    min-width: 300px;
    max-width: 1000px;
  }

  &__topfold {

    &__attention {
      margin-top: 3%;
      max-width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }

    &__delivery {
      margin-top: 3%;
      margin-left: auto;
      margin-right: auto;
      max-width: 90%;
    }

    &__comment {
      margin-top: 5%;
      margin-bottom: 3%;
      margin-left: auto;
      margin-right: auto;
      &__image {
        display: block;
        margin: 0 auto;
        min-width: 270px;
        width: 90%;
        border-radius: 7px;
      }
    }

    &__button {
      margin-top: 5%;
      margin-bottom: 3%;

      &__image {
        flex: auto;
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
        max-width: 20%;
        margin-left: 2%;
        margin-top: 2%;
        margin-bottom: 2%;
      }

      &__text {
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10%; 

        > span {
          color: #565656;
        }
      }
    }


    &__message {
      // font-size: 50px;
      color: #797979;
      margin-left: 5%;
      margin-right: 5%;
      max-width: 90%;
    }
  }

  &__pencil {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    min-width: 300px;
    max-height: 100px;
    max-width: 1000px;

    &__image {
      // margin: 0 auto;
      // padding-bottom: 2%;
      max-height: 100px;
      min-width: 300px;
      max-width: 1000px;
      border-radius: 7px;
      -webkit-filter: drop-shadow( 3px 3px 2px rgba(154, 160, 185, .3));
      filter: drop-shadow( 3px 3px 2px rgba(154, 160, 185, .3));
    }
  }

  &__pen {
    max-height: 150px;
  }
}

.gdocs {
  width: 80%;
  max-width: 100px;
  padding-bottom: 16%;
  -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
  filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
}

.star {
  padding-bottom: 22%;
}

.blog {
  padding-bottom: 10%;
  position: relative;
  top: -10px;
}

.config {
  padding-top: 7%;
  padding-bottom: 19%;
}

.glasses {
  padding-top: 12%;
  padding-bottom: 20%;
  position: relative;
  top: -10px;
}

.genericer-button {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: nowrap;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 7px;
  box-shadow: 1.5px 1.5px 4px 0 hsla(0%, 0%, 0%, .5);
  background-color: #C9E6FE;
  width: 90%;
  height: 10vh;
  max-height: 14vh;
  max-width: 400px;
  min-width: 250px;
  overflow: hidden;

}

@media (max-width: 500px) {
  .landing__topfold__button__text {
    padding-top: 4%;
    padding-bottom: 4%;
  }

  .landing__fold {
    width: 90%;
  }
}

@media (min-width: 1100px) {
  .landing__topfold {
    margin-top: -2%;
  }

  .landing__logo__image {
    margin-top: 1%;
    margin-left: 1%;
  }
}

@media (min-width: 1200px) {
  .landing__topfold {
    margin-top: -5%;
  }
}

@media (min-width: 1300px) {
  .landing__topfold {
    margin-top: -7%;
  }
}

@media (min-width: 1400px) {
  .landing__topfold {
    margin-top: -8%;
  }
}

@media (min-width: 1600px) {
  .landing__topfold {
    margin-top: -3%;
  }
}
