.indicator {
  text-decoration: none;

  :hover {
    outline: none;
  }

  :active {
    outline: none;
  }

  :focus {
    outline: none;
  }
}

.test {
  width: 100%;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  height: initial;
  // justify-content: center;
  align-items: center;
  max-width: 850px;
  padding: .5%;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 1%;
  padding: 0;
  overflow: hidden;
}

@media (max-width: 850px) {
  .config-page-fold {
    width: 100%;
    border-radius: 0px;
    margin-top: 0%;
    margin-bottom: 0%;
  }
  .test {
    margin: 0;
    padding: 0;
    margin-top: 1%;
  }
}
