.app {
  height: 100vh;
  max-height: 100vh;
  background-color: #E7F8FF;
  scroll-behavior: smooth;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  min-width: 320px;
}

.app::-webkit-scrollbar { /* WebKit */
  width: 0;
  height: 0;
}
