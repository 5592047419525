.admin {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &-add-docs {
    width: 60%;
    height: 20%;
  }
}
