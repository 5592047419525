.row-display {
  padding-top: 1%;
  padding-bottom: 1%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.row-min-height {
  min-height: 44px;
}

.reviewer-avatar-display {
  border-radius: 50%;
  max-height: 42px;
  &:hover {
    z-index: 1000;
  }
}
