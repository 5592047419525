.reviewstatus-icon {
  margin-left: auto;
  position: relative;
}

.request-review {
  width: 100%;
  display: flex;
  justify-content: center;
}

.right-col-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.delete-post {
  display: flex;
  padding-right: 6%;
}

.launch-link {
  text-decoration: none;
  color: black;
}

.remove-circle {
  background: radial-gradient(circle at center, #ffe83b 0, #ffe83b 60%, rgba(0,0, 0, 0) 60%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.gear {
  font-size: 24px;
}
