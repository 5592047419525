.trifolditem {
  width: 33%;
  min-width: 200px;
  margin-top: 2%;
  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-content {
    width: 80%;
    margin-top: 5%;
    margin-bottom: 5%;
    margin: 0 auto;
  }

  &-image {
    // height: auto;
    // max-width: 75px;
    max-height: 200px;
  }
}

@media (max-width: 800px) {
  .trifolditem {
    min-width: 80%;
    margin: 0 auto;
    padding-bottom: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-image {
      min-height: 175px;
    }
  }
}
