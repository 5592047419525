.nav-wrapper {
  width: 100%;
  height: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-content {
    height: 100%;
    display: flex;
    overflow: hidden;
    // overflow-y: auto;
    // overflow-y: auto;
  }
}

.nav {
  flex: 0 0 10%;
  min-height: 20px;
  min-width: 350px;
  display: flex;
  flex-direction: column;

  &-image {
    max-width: 50px;
    height: auto;
    margin-right: 100px;

    &-blue {
      border-radius: 50%;
    }
  }

  &-link {
    font-size: 2.3em;
    height: 40px;
    width: 40px;
  }

  &-links {
    width: 30%;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    justify-content: space-between;
    max-width: 130px;
  }
}

.fa-home {
  color: black;
}

.fa-table {
  color: black;
}

.navpencil-spacer {
  flex-grow: 1;
}

.navpencil-wrapper {
  flex-grow: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navpencil {
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  max-width: 800px;

  &__image {
    max-height: 100px;
    min-width: 200px;
    max-width: 800px;
    border-radius: 7px;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(154, 160, 185, .3));
    filter: drop-shadow( 3px 3px 2px rgba(154, 160, 185, .3));
  }
}


.navpencil {
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  max-width: 800px;
  min-width: 300px;

  &__image {
    max-height: 100px;
    min-width: 200px;
    max-width: 800px;
    border-radius: 7px;
    -webkit-filter: drop-shadow( 3px 3px 2px rgba(154, 160, 185, .3));
    filter: drop-shadow( 3px 3px 2px rgba(154, 160, 185, .3));
  }
}

@media (max-width: 800px) {
  .navpencil {
    display: none;
  }
}
