.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.animation-container {
  width: 100%;
  height: 100%;
  position: absolute;  
}

.animation-nested {
  width: 100%;
  height: 100%;
  clip-path: polygon(87.5% 0%, 87.5% 100%, 190% 100%, 190% 0);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
}

.animation-image {
  position: relative;
  width: 70%;
  padding-bottom: 3%;
  transform: rotateY(180deg);
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation: myfirst 14s infinite;
}

@keyframes myfirst {
  from {
    left: 90%;
  }
  to {
    left: 30%;
  }
}

.animation-shavings {
  position: absolute;
  top: 7%;
  left: 5%;
  height: 61%;
  width: 60%;
  overflow: hidden;
}

.sharpener {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  background: url('../images/sharp.svg') no-repeat;
}

.sharpener-image {
  height: auto;
  width: 36.5%;
  position: relative;
}

// .shavings {
//   height: 100%;
//   width: 100%;
//   left: 4.5%;
//   position: absolute;
//   z-index: 0.1;
//   animation-timing-function: linear;
//   -webkit-animation-timing-function: linear;
//   animation: shavingsanim 14s infinite;
// }

// .shavings-dynamic {
//   height: 100%;
//   width: 100%;
//   left: 1.5%;
//   position: absolute;
//   z-index: 0.1;
//   display: flex;
//   align-items: center;

//   &-cover {
//     width: 100%; 
//     height: 28%;
//     padding-right: 77.5%;
//     padding-bottom: 4%;
//   }
// }

// @keyframes sidewaysshave {
//   from {
//     clip-path: polygon(0 0%, 0 55%, 0% 55%, 0% 0%);
//   }
//   to {
//     clip-path: polygon(0 0%, 0 55%, 20% 55%, 20% 0%);
//   }
// }

// @keyframes shavingsanim {
//   from {
//     clip-path: polygon(0 55%, 0 55%, 100% 55%, 100% 55%);
//   }
//   to {
//     clip-path: polygon(0 55%, 0 40%, 100% 40%, 100% 55%);
//   }
// }

// .shavings-image {
//   width: 20%;
//   height: 100%;
// }


// @-webkit-keyframes myfirst {
//   from {
//     right: 50px;
//   }
//   to {
//     right: 500px;
//   }
// }
