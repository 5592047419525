.loadertextbutton {
  &-image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  &-image-wrapper {
    position: relative;
    min-width: 125px;
    min-height: 75px;
    height: 100%;
    max-height: 75px;
    width: auto;
    margin: 0 auto;
  }
}

.sharpener-wrapper {
  position: absolute;
  height: 75px;
  min-height: 100px;
  max-height: 100px;
  width: 100%;
  height: 40%;
}
