.added-reviewer {
  // border: 1px solid rgba(0, 0, 0, 0.1);
  // border-radius: 3px;
  // padding: 0.5%;
  width: 100%;

  &-wrapper {
    overflow: hidden;
    cursor: pointer;
    border: none;
    max-width: 100%;
    height: 35%;
    min-height: 50px;
    background-color: #E7F8FF;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 0;
    box-shadow: 0 5px 10px mix(rgba(#C9E6FE, .2), rgba(154,160,185, 0.2)), 0 15px 40px mix(rgba(#C9E6FE, .2), rgba(166,173,201, .05)), 1.5px 1.5px 4px 0 mix(rgba(#C9E6FE, .2), rgba(166, 173, 201, .5));
    margin-bottom: 1%;
  }

  &-wrapper:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 255, .5);
  }

  &-collapse {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #F5F5F5;
    border-radius: 3px;
  }

  &-email {
    border-radius: 3px;
    height: 50%;
    margin-left: 2%;
    padding: 1%;
    white-space: nowrap;
    max-width: 80%;
  }

  &-spacer-4 {
    flex-grow: 4;
  }

  &-spacer-1 {
    flex-grow: 1;
  }

  &-spacerpx-20 {
    min-height: 20px;
  }

  &-remove {
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    min-height: 100%;
    background-color: #FFECEC;
    min-height: 50px;
    border-radius: 3px;
    border: 0px;
  }

  &-remove:hover {
    box-shadow: 0px 0px 5px rgba(255, 0, 0, .5);
  }
}
@media (max-width: 600px) {
  .added-reviewer {
    &-email {
      max-width: 60%;
      overflow-wrap: break-word;
      white-space: normal;
    }
    &-hidden-responsive {
      display: none;
    }
  }
}
