@import 'Trifold';

.inner-margin {
  padding: 2%;
  padding-bottom: 30px;
}

.config-page {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  min-width: 300px;
  max-height: 100%;
  padding-top: 1%;
  padding-bottom: 1%;

  &-fold {
    @extend .basic-fold;
    background-color: white;
    flex-grow: 1;
    width: 98%;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &-content {
    position: relative;
    background-color: #F5FBFF;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.1);

    border-radius: 7px;
    box-shadow: 1.5px 1.5px 4px 0 hsla(0%, 0%, 0%, .5);

    &-title {
      position: relative;
      margin: 0 auto;
      font-family: Helvetica Neue;
      font-size: 28px;
      font-weight: 700;
      width: 80%;
      text-align: center;
      margin-bottom: 1%;
      background-color: white;
    }

    &-body {
      position: relative;
      width: 80%;
      margin: 0 auto;
    }
  }
}

@media (max-width: 800px) {
  .config-page {
    width: 100%;
    min-height: 100%;
    padding-top: 0%;
    padding-bottom: 0%;
  }

  .config-page-fold {
    padding-top: 5%;
    width: 100%;
    border-radius: 0px;
    margin-top: 0%;
    margin-bottom: 0%;
  }

  .config-page-content-title {
    margin-top: 1%;
  }

  .inner-margin {
    padding-bottom: 10px;
  }

  .configpencil-wrapper {
    display: none;
  }
}

@media (max-width: 500px) {
  .config-page-content-title {
    text-align: left;
  }
}
