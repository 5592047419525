.basic-fold {
  position: relative;
  background-color: #F5FBFF;
  border-radius: 7px;
  box-shadow: 0 5px 10px mix(rgba(#C9E6FE, .2), rgba(154,160,185, 0.2)), 0 15px 40px mix(rgba(#C9E6FE, .2), rgba(166,173,201, .05)), 1.5px 1.5px 4px 0 mix(rgba(#C9E6FE, .2), rgba(166, 173, 201, .5));
  width: 80%;
  height: auto;
  margin: 0 auto;
  min-width: 300px;
  max-width: 1000px;

  &-blocker {
    position: absolute;
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    background-color: rgba(#ffffff, 0.8);
    border-radius: 5px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    &-spinner {
      align-self: center;
      width: 40%;
      height: 40%;
      z-index: 1001;
    }
  }
}

.genericest-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 7px;
  box-shadow: 1.5px 1.5px 4px 0 hsla(0%, 0%, 0%, .5);
  background-color: #C9E6FE;
  width: 90%;
  height: 10vh;
  max-width: 400px;
  min-width: 250px;
  overflow: hidden;

}

.trifold {
  &__attention {
    margin-top: 5%;
    // margin-left: auto;
    // margin-right: auto;
    margin-left: 5%;
    padding-bottom: 5%;
    max-width: 90%;
  }

  &__content {
    display: flex;
    margin-top: 5%;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-left: 5%;
    margin-right: 2%;
  }

  &__button {
    text-align: center;
    margin-bottom: 3%;

    &__image {
      flex: auto;
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: auto;
      max-width: 20%;
      margin-left: 2%;
      margin-top: 2%;
      margin-bottom: 2%;
    }

    &__text {
      margin: 0 auto;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 10%;

      > span {
        color: #565656;
      }
    }
  }
}

@media (max-width: 500px) {
  .basic-fold {
    width: 90%;
  }

  .trifold {
    &__attention {
      text-align: center;
    }
  }
}

@media (max-width: 800px) {
  .trifold {
    &__content {
      flex-wrap: wrap;
    }
  }
}
