.googlelogobutton {
  &-image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  &-image-wrapper {
    position: relative;
    min-width: 75px;
    min-height: 75px;
    max-width: 20%;
    margin-left: 2%;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  &-child-component {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
  }
}

.cross-fade-leave {
  opacity: 1;
}

.cross-fade-leave.cross-fade-leave-active {
  opacity: 0;
  transition: opacity 1s ease-in;
}

.cross-fade-enter {
  opacity: 0;
}
.cross-fade-enter.cross-fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.cross-fade-height {
  transition: height 0.5s ease-in-out;
}
