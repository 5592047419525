.post-form {
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;

  &-labels {
    font-weight: bold;
  }

  &-reviewtype {
    &-options {
      border: 2px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      padding: 5%;
    }
  }

  &-submission {
    position: relative;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
