body {
  --main-white: rgba(255, 255, 255, 1.0);
  --off-white: #F5F5F5;
  --bg-color: #E7F8FF;
  --button-color: #C9E6FE;
  --accent-color: blue;
  --border-radius: 7px;
  --softer-black: #565656;
  font-family: Helvetica Neue;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

.centered-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
