@import '../index.scss';

.generic-button {
  @extend .unselectable;

  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;

  width: 100%;
  height: auto;
  // height: 100%;

  border-radius: var(--border-radius);

  background-color: var(--button-color);
  border-bottom: 1px solid var(--accent-color);
  box-shadow: 1.5px 1.5px 4px 0 hsla(0%, 0%, 0%, .5);

  cursor: pointer;
}

.generic-button-disabled {
  background-color: rgba(150, 190, 220, 0.3);
}

@media (max-width: 500px) {
  .generic-button__text {
    padding-top: 4%;
    padding-bottom: 4%;
  }
}
